import { NavLink } from "react-router-dom";

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/Auto Mart Logo/g6.png";
import { Dropdown } from "flowbite-react";
import { motion } from "framer-motion";
import { RiHome2Line } from "react-icons/ri";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import axios from 'axios';
import "../marquee.css";
import CartCount from './CartCount'; 
import { GiHomeGarage } from "react-icons/gi";
import { IoCarSport } from "react-icons/io5";

import { BiSolidHelpCircle } from "react-icons/bi";
import {
  BsFillPersonVcardFill
} from "react-icons/bs";
import { IoBagHandle } from "react-icons/io5";
import {  PiSignOutThin } from "react-icons/pi";
import Avatar from "../images/Profileimg.jpeg";
import { jwtDecode } from "jwt-decode";
import { CgShoppingCart } from "react-icons/cg";
import { Cookies } from "react-cookie";
// ... Other imports ...
import Logosmall from "../assets/Auto Mart Logo/g116.png";
import { useNavigate } from "react-router-dom";
import { useCart } from "../ExcessCode/CartContext";
import SearchBar from "./SearchComponent";
import { HiMenu } from "react-icons/hi";


const isActive = (path, location) => {
  return location.pathname === path;
};


const cookies = new Cookies();
const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

document.addEventListener("DOMContentLoaded", function () {
  const avatarButton = document.getElementById("avatarButton");
  if (avatarButton) {
    const userDropdown = document.getElementById("userDropdown");

    const dropdown = new Dropdown(avatarButton, {
      placement: avatarButton.getAttribute("data-dropdown-placement"),
      reference: avatarButton,
      popper: userDropdown,
    });
  }
});

export function Navibar({ toggleSidebar, isSidebarOpen, isNavMenubarActive }) {
  
  const { totalProducts } = useCart();

  const [showMobileNavbar, setShowMobileNavbar] = useState(false);
  const isMobileScreen = window.innerWidth <= 768;
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [count , setCartCount]= useState(0);
  const navigate = useNavigate();
  const { updateTotalProducts } = useCart();
  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [userData, setUserData] = useState(null);
  const { UserName, UserEmail } = userData || {};
  

  // useEffect(() => {
  //   const token = cookies.get("token");

  //   // const token = localStorage.getItem('token');

  //   if (token) {
  //     try {
  //       const decoded = jwtDecode(token);

  //       setUserData({
  //         UserName: decoded.name,
  //         UserEmail: decoded.email,
  //       });
  //     } catch (error) {
  //       console.error("Error decoding the token:", error);
  //     }
  //   }
  // }, []);
  const fetchData = async () => {
    const token = cookies.get("token");

    if (token) {
      try {
        const decoded = jwtDecode(token);

        // Assuming setUserData is a synchronous function
        setUserData({
          UserName: decoded.name,
          UserEmail: decoded.email,
        });
      } catch (error) {
        console.error("Error decoding the token:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  

  // Function to close the search input
  const closeSearch = () => {
    setSearchOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close search bar when clicking outside
      if (isSearchOpen) {
        const searchInput = document.getElementById("search");
        if (searchInput && !searchInput.contains(event.target)) {
          setSearchOpen(false);
        }
      }

      // Close dropdown when clicking outside
      const avatarButton = document.getElementById("avatarButton");
      if (avatarButton && !avatarButton.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSearchOpen]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    //cart count 
  
  // for scroll of searchbar
  // const glassmorphismStyles = {
  //   background: "rgba(223, 179, 190, 0.25)",
  //   boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
  //   backdropFilter: "blur(4.5px)",
  //   WebkitBackdropFilter: "blur(4.5px)",
  //   borderRadius: "10px",
  //   border: "1px solid rgba(255, 255, 255, 0.18)",
  // };

  // const glassdrawer = {
  //   background: "rgba( 232, 232, 232, 0.85 )",
  //   boxShadow: " 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
  //   backdropFilter: "blur( 10px )",
  //   WebkitBackdropFilter: "blur( 10px )",
  //   borderRadius: "10px",
  //   border: "1px solid rgba( 255, 255, 255, 0.18 )",
  // };

  const token = cookies.get("token", { path: "/" });
  const isLoggedIn = token !== null && token !== undefined && token !== "";
  const handleLogout = async () => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("token");
      // Call your logout API passing the authorization header with the token
      await axios.get(
        'https://api.auto-mart.co.in/api/logout',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Remove the 'token' cookie only after a successful response
      await cookies.remove('token', { path: '/' });

      // Redirect to '/login'
      navigate('/login');
      window.location.reload();

     
    } catch (error) {
      cookies.remove('token', { path: '/' });
      console.error('Error during logout:', error);
      navigate("/")
      window.location.reload();
      // Handle the error or redirect if necessary
    }
  };



  return (
    <>
      {/* Start of Mobile Navigation view  
   //        M   M   OOO   BBB   III  L       EEEE
   //        MM MM  O   O  B   B   I   L      E
   //        M M M  O   O  BBB     I   L      EEE
   //        M   M  O   O  B   B   I   L      E
   //        M   M   OOO   BBB   III  LLLLL  EEEE
*/}

      <nav className="bg-[#1f0322ec] dark:bg-gray-900 rounded-b-lg sticky z-50 top-0 ">
        {/* top search bar for mobile view below  */}
        {isMobileScreen ? (
          <div>
            <CartCount />
            <div className="mobile-navbar ">
              <div
                className={`sticky pl-4 transform-translate-x-1/2 flex top-1 justify-center items-center z-50 py-2 rounded ${
                  isActive("/navmenu", location) ? "hidden lg:block " : ""
                }`}
              >
                <div className="logo mr-2">
                  <NavLink exact to="/" className="flex text-md ">
                    <img
                      src={Logosmall}
                      className=" "
                      style={{ width: "40px", height: "30px" }}
                      alt="automart Logo"
                    />
                  </NavLink>
                </div>
                {/* <div className="absolute right-20 transform-translate-y-1/2 flex items-center pl-2 pointer-events-none">
                  <BsSearch className="text-vivamagenta-accent font-semi-bold" />
                </div> */}

{/* new searchbar  */}
<SearchBar/>
                {/* <input
                  type="text"
                  id="search"
                  className={`bg-gray-50 border mr-3 opacity-100 hover:opacity-80 border-vivamagenta-accent text-vivamagenta-accent sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-500 block w-full px-8 py-1.5 md:w-64  ${
                    isActive("/navmenu", location) ? "hidden lg:block" : ""
                  }`}
                  placeholder="&nbsp;Search Automart.."
                  onClick={toggleSearch}
                /> */}

                <button className="w-max-full p-2 mr-2 rounded-6xl ">
                  <NavLink exact to="/cart" className="">
                    <CgShoppingCart className="text-snowy-white font-extrabold text-3xl" />
                    <span className="absolute top-3 right-1 bg-red-500  mr-2 px-1 font-bold rounded-full text-xs text-white">
    {parseInt(totalProducts)}
    </span>
                  </NavLink>
                </button>
              </div>
            </div>

            <div className="fixed bottom-0 z-50 w-full bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
              <div className="max-w-screen-xl p-0 m-2">
                <div className=" flex items-center justify-center">
                  <div>
                    <div className="">
                      <ul className="flex gap-x-7">
                        <li className="" onClick={toggleSidebar}>
                          <NavLink
                            exact
                            to="/"
                            className={`flex flex-col items-center text-gray-600 ${
                              isActive("/", location)
                                ? "text-vivamagenta-accent font-semibold"
                                : ""
                            }`}
                          >
                        <RiHome2Line className="text-2xl"/>
                            Home
                          </NavLink>
                        </li>
                        <li className="" onClick={toggleSidebar}>
                          <NavLink
                            exact
                            to="/shop"
                            className={`flex flex-col items-center text-gray-600 ${
                              isActive("/shop", location)
                                ? "text-vivamagenta-accent font-semibold"
                                : ""
                            }`}
                          >
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5 h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                              />
                            </svg> */}
                            <HiOutlineShoppingBag  className="text-2xl  " />
                            Shop
                          </NavLink>
                        </li>
                        <li className=" " onClick={toggleSidebar}>
                          <NavLink
                            exact
                            to="/reuse"
                            className={`flex flex-col items-center text-gray-600 ${
                              isActive("/reuse", location)
                                ? "text-vivamagenta-accent font-semibold"
                                : ""
                            }`}
                          >
                            <IoCarSport className="text-2xl" />
                            Reuse
                          </NavLink>
                        </li>
                        <li className=" " onClick={toggleSidebar}>
                          <NavLink
                            exact
                            to="/services"
                            className={`flex flex-col items-center text-gray-600 ${
                              isActive("/services", location)
                                ? "text-vivamagenta-accent font-semibold"
                                : ""
                            }`}
                          >
                            <GiHomeGarage className="text-2xl" />
                             Services
                          </NavLink>
                        </li>
                        <li className="" onClick={toggleSidebar}>
                          <NavLink
                            exact
                            to="/navmenu"
                            className={`flex flex-col items-center text-gray-600 ${
                              isActive("/navmenu", location)
                                ? "text-vivamagenta-accent font-semibold"
                                : ""
                            }`}
                          >
                            {/* user svg  */}
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-6 h-6"
                            >
                              <path d="M18.75 12.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM12 6a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 6zM12 18a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 0112 18zM3.75 6.75h1.5a.75.75 0 100-1.5h-1.5a.75.75 0 000 1.5zM5.25 18.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM3 12a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 013 12zM9 3.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM12.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0zM9 15.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
                            </svg> */}
                            <HiMenu className="text-2xl" />
                            Menu
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // Start of Desktop Navigation View

          //
          //   DDDDD   EEEEE   SSS   K   K  TTTTT  OOO  PPPP
          //   D     D  E      S     K  K     T   O   O P   P
          //   D       EEEE     SS   KKK      T   O   O PPPP
          //   D     D  E          S  K  K     T   O   O P
          //  DDDDD   EEEEE  SSS   K   K    T    OOO  P
          //
          //

          <div className=" max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4 sticky z-50  top-0  ">
            <NavLink to="/" className="flex py-2 px-3 items-center">
              <img src={logo} className="h-8 mr-3" alt="automart Logo" />
            </NavLink>

            <CartCount />
{/* new searchbar  */}
<SearchBar/>

            {/* <div
              onClick={toggleSearch}
              className="searchbar px-2 text-md bg-gray-50 border mr-3 opacity-100 hover:opacity-80 border-white text-vivamagenta-accent sm:text-sm rounded-full  focus:ring-blue-600 focus:border-blue-500   ring-[2px] ring-vivamagenta-accent"
            >
              <div className="justify-between  py-2 px-8 text-dark flex items-center pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>{" "}
                <span className="text-sm  ">Search Automart</span>
              </div>
            </div> */}
            <div
              className=" items-center p-2 justify-between hidden w-full md:flex md:w-auto md:order-1"
              id="navbar-user"
            >
              <ul
                className={`${
                  showMobileNavbar ? "block" : "hidden md:flex"
                } flex-col font-medium p-2 md:p-0 mt-6 border border-gray-100 rounded-lg  md:flex-row md:space-x-8 md:mt-2 md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700`}
              >
                <li>
                  <NavLink
                    exact
                    to="/"
                    onClick={() => navigate("/")}
                    className={`block py-2 pl-3 pr-4 text-snowy-white rounded md:text-dark md:p-0 md:dark:text-blue-500 ${
                      isActive("/", location) ? "text-vivamagenta-accent font-semibold " : ""
                    }`}
                  >
                    Home
                  </NavLink>
                </li>

                <li>
                <NavLink
                            exact
                            to="/shop"
                            className={`block py-2 pl-3 pr-4 text-snowy-white rounded md:text-dark md:p-0 md:dark:text-blue-500 ${
                              isActive("/shop", location)
                                ? "text-vivamagenta-accent font-semibold "
                                : ""
                            }`}
                          >
                          
                            Shop
                          </NavLink>
                </li>
                <li>
                <NavLink
                            exact
                            to="/reuse"
                            className={`block py-2 pl-3 pr-4 text-snowy-white rounded md:text-dark md:p-0 md:dark:text-blue-500 ${
                              isActive("/reuse", location)
                                ? "text-vivamagenta-accent font-semibold "
                                : ""
                            }`}
                          >
                          
                          Reuse
                          </NavLink>
                 
                </li>
                <li>
                <NavLink
                            exact
                            to="/services"
                            className={`block py-2 pl-3 pr-4 text-snowy-white rounded md:text-dark md:p-0 md:dark:text-blue-500 ${
                              isActive("/services", location)
                                ? "text-vivamagenta-accent font-semibold "
                                : ""
                            }`}
                          >
                          Services
                          </NavLink>
                  
                </li>
                <li>
                <NavLink
                            exact
                            to="/business"
                            className={`block py-2 pl-3 pr-4 text-snowy-white rounded md:text-dark md:p-0 md:dark:text-blue-500 ${
                              isActive("/business", location)
                                ? "text-vivamagenta-accent "
                                : ""
                            }`}
                          >
                           Business
                          </NavLink>
                 
                </li>
              </ul>

              <button className="w-max-full mt-2 pl-4 ml-2 relative">
              <NavLink
    exact
    to="/cart"
    className="text-snowy-white font-bold flex mt-2 relative"
  >
  <CgShoppingCart className="text-snowy-white mr-2 mb-2 text:border-[#fff] text-2xl relative" />
    <span className="absolute top-0 right-0 bg-red-500 mb-1 mr-1 px-1 rounded-full text-xs text-white">
      {parseInt(totalProducts)}
    </span>
</NavLink>
</button>

              <div className="pl-5 ml-5 flex space-x-2">
                {isLoggedIn ? null : (
                  <>
                    {" "}
                    <NavLink
                      to="/login"
                      className="mt-4 block w-full mb-2 px-4 py-2 text-center rounded-md bg-vivamagenta-accent text-snowy-white font-semibold hover:bg-vivamagenta-accent-dark focus:outline-none focus:bg-vivamagenta-accent-dark"
                    >
                      Login
                    </NavLink>
                    <NavLink
                      to="/register"
                      className="mt-4 block w-full mb-2 px-4 py-2 text-center rounded-md bg-vivamagenta-accent text-snowy-white font-semibold hover:bg-vivamagenta-accent-dark focus:outline-none focus:bg-vivamagenta-accent-dark"
                    >
                      Register
                    </NavLink>
                  </>
                )}
              </div>
              <div className="relative ml-5 ">
                {isLoggedIn ? (
                  <div
                    className="relative cursor-pointer z-10 " 
                    onClick={() => { toggleDropdown(); fetchData(); }}
                  >
                    <img
                      src={Avatar}
                      className="w-10 h-10 rounded-full"
                      alt="User profile"
                    />
                    {isDropdownOpen && (
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants}
                        className="absolute top-12 z-10 right-0 mt-2 w-44 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                      >
                        <div className="px-4 py-3 text-sm text-gray-900 dark:text-snowy-white z-50">
                          {isLoggedIn ? (
                            <>
                              <Link to="/user/myprofile"><div>{UserName}</div></Link>
                              <div className="font-medium truncate">
                                {UserEmail}
                              </div>
                            </>
                          ) : null}
                        </div>
                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                        <li>
                            <Link
                              to="/user/myorders"
                              className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-dark"
                            >
                              <IoBagHandle className="inline text-xl mr-2" />{" "}
                             My Orders
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/user/vehicledetails/myvehicle"
                              className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-dark"
                            >
                              <IoCarSport className="inline text-xl mr-2" />{" "}
                              My Vehicles
                            </Link>
                          </li>
                        
                          <li>
                            <Link
                              to="/business"
                              className="block px-4 py-2 hover-bg-gray-100 dark:hover-bg-gray-600 dark:hover-text-dark"
                            >
                              <BsFillPersonVcardFill className="inline text-xl mr-2" />
                              Business
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/helpcenter"
                              className="block px-4 py-2 hover-bg-gray-100 dark:hover-bg-gray-600 dark:hover-text-dark"
                            >
                              <BiSolidHelpCircle className="inline text-xl mr-2" />
                              Help & Support
                            </Link>
                          </li>
                        </ul>
                        <div className="py-1">
                          {isLoggedIn && (
                            <NavLink
                            onClick={handleLogout}
                              className="block px-4 py-2 text-sm text-gray-700 hover-bg-gray-100 dark:hover-bg-gray-600 dark:text-gray-200 dark:hover-text-dark"
                            >
                              <PiSignOutThin className="inline text-xl mr-2" />{" "}
                              Logout
                            </NavLink>
                          )}
                        </div>
                      </motion.div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </nav>


      {isSearchOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isSearchOpen ? 1 : 0 }}
          transition={{ duration: 0.5 }}
          style={{ backgroundColor: "rgba(208, 36, 71, 0.90)" }} 
          className={`fixed inset-0 z-50 ${isSearchOpen ? "block" : "hidden"}`}
        >
          <div className="absolute inset-0 flex items-start mt-12 pt-12 justify-center">
            <div className="relative">
              <input
                type="text"
                id="search"
                className="bg-gray-50 border border-gray-300 text-vivamagenta-accent sm:text-sm rounded-lg focus:ring-veriperi-blue focus:border-blue-500 block w-full px-12 py-3 md:w-64"
                placeholder="Search Automart.."
              />
              <button
                className="absolute top-0 right-0 m-3 text-gray-500"
                onClick={closeSearch} 
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
}
