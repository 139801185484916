import { Typography } from "@material-tailwind/react";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/boguslogoAsset 1-8.png";
import { FaHeart } from "react-icons/fa";

const SITEMAP = [
  {
    title: "HELP CENTER",
    links: [
      { label: "Help & Support", to: "/helpcenter" },
      { label: "Terms & conditions", to: "/termsandcondition" },
      { label: "Privacy Policy", to: "/privacypolicy" }, 
      { label: "Shipping Policy", to: "/shippingpolicy" },
      { label: "Refunds & Cancellation", to: "/returnpolicy" }, 
       
      ],
  },
  {
    title: "Social",
    links: [
      { label: "Facebook", url: "https://www.facebook.com/profile.php?id=100093089197019", external: true },
      { label: "Twitter", url: "https://twitter.com/MyAutomart2023", external: true },
      { label: "Instagram", url: "https://www.instagram.com/automartshop", external: true  },
      { label: "Whatsappp", url: "https://wa.link/vma6mc" , external: true },
   
    ],
  },
  {
    title: "Registered Office",
    links: [
      { label: "Auto Mart",  },
      { label: "A109-Roongta Complex,Dwarka," },
      { label: "Nashik,Maharashtra,India-422001" },
      { label: "Email: info@auto-mart.co.in"},
      { label: "Phone: +918530598477"},
    ],
  },
];

const currentYear = new Date().getFullYear();

export function FooterWithSitemap() {
  const isMobileScreen = window.innerWidth <= 768;
  if (isMobileScreen) {
    return null;
  }

  return (
    <footer className="relative w-full bg-snowy-white text-white rounded-t-lg">
      <div className="mx-auto w-full max-w-7xl px-8">
        <div className="grid w-full grid-cols-1 gap-8 py-12 md:grid-cols-2 md:justify-center md:flex lg:grid-cols-4">
          {SITEMAP.map(({ title, links }, key) => (
            <div key={key} className="md:w-full text-center">
              <Typography
                variant="small"
                className="mb-4 font-bold bg-vivamagenta-accent uppercase opacity-50"
              >
                {title}
              </Typography>
              <ul className="space-y-1">
                {links.map((link, key) => (
                  <Typography
                    key={key}
                    as="li"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {link.external ? (
                      <a
                        href={link.url}
                        target="_blank"
                        className="inline-block py-1 pr-2 transition-transform hover:scale-105"
                      >
                        {link.label}
                      </a>
                    ) : (
                      <Link
                        to={link.to || ""}
                        className="inline-block py-1 pr-2 transition-transform hover:scale-105"
                      >
                        {link.label}
                      </Link>
                    )}
                  </Typography>
                ))}
              </ul>
            </div>
          ))}
          <div className="justify-around top-4 from-blue-gray-50 to-white text-center">
            <img src={logo} alt="automart-logo" label="Automart" />

          </div>
        </div>
        <div className="flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
          <Typography
            variant="small"
            className="mb-4 text-center font-normal text-vivamagenta-accent md:mb-0"
          >
            &copy; {currentYear} <a >Automart</a>. All Rights Reserved.
          </Typography>
          <div className="flex space-x-1">
            <h2 className="text-vivamagenta-accent flex ">Made With<FaHeart className="text-red-500 mt-1 mx-1 animate-pulse  "/>In India</h2>
          </div>
          <div className="flex gap-4 text-blue-gray-900 sm:justify-center">
            <p className="text-sm text-vivamagenta-accent ">
             Developed By ShivKalp IT Services.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
