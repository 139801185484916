import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import { Input } from "@mui/material";
import { CgSearch } from "react-icons/cg";
import { Listbox, Transition } from '@headlessui/react';
import { RxCaretDown ,RxChevronUp } from 'react-icons/rx';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useNavigate } from "react-router-dom";
const CustomSelect = ({ options, selectedOption, onChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Listbox value={selectedOption} onChange={onChange}>
      {({ open }) => {
        if (open) {
          setIsDropdownOpen(true);
        }else{
          setIsDropdownOpen(false);
        }

        return (
          <>
            <Listbox.Button
              className="relative  lg:pl-2 lg:pr-5 md:pr-10 w-32 text-left bg-white rounded-full lg:w-32 "
              onBlur={() => setIsDropdownOpen(false)}
            >
              <span className="block  text-center ">{selectedOption}</span>
              <span className="absolute inset-y-3 right-1 items-center ">
                
                  {(isDropdownOpen ==='true' || open) && (
              
                      <RxChevronUp className=" text-gray-700 text-xl md:text-2xl" />
                  
                  )}
                 {!isDropdownOpen && !open && (
  <RxCaretDown className=" text-xl md:text-2xl text-gray-700 " />
)}

              
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-11 lg:mt-14 rounded w-32 bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-start"
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      `${
                        active
                          ? "text-white bg-vivamagenta-accent"
                          : "text-gray-900"
                      } cursor-default select-none relative py-2 pl-8 pr-2`
                    }
                    value={option.id}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`${
                            selected ? "font-medium" : "font-normal"
                          } block truncate`}
                        >
                          {option.name}
                        </span>
                        {selected && (
                          <span
                            className={`${
                              active ? "text-white" : "text-vivamagenta-accent"
                            } absolute inset-y-0 left-0 flex items-center pl-2`}
                          >
                            <CheckIcon className="h-5 w-5" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        );
      }}
    </Listbox>
  );
};


const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Shop");
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  const handleCategoryChange = (category) => {
    setSearchTerm("");
    setSelectedCategory(category);
  };

  const handleEnterPress = async () => {
    try {
      const response = await axios.get(
        `https://api.auto-mart.co.in/api/get/search/${selectedCategory}/${searchTerm}`,{
          headers: {
            Authorization: `${process.env.REACT_APP_API_KEY}`
          }
        }
      );
      const searchResults = response.data;
        navigate('/major', { state: { searchResults, selectedCategory } });
    
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleSearch = async () => {
    try {
      if (searchTerm.trim() !== "") {
        const response = await axios.get(
          `https://api.auto-mart.co.in/api/get/search/${selectedCategory}/${searchTerm}`,{
            headers: {
              Authorization: `${process.env.REACT_APP_API_KEY}`
            }
          }
        );
        setSearchResults(response.data);
        navigate("/major", { state: { searchResults, selectedCategory } });
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [selectedCategory, searchTerm]);

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="flex bg-white rounded-full lg:mt-2  h-10 lg:h-12"
    >
      <CustomSelect
        options={[
          { id: "Shop", name: "Shop" },
          { id: "Reuse", name: "Reuse" },
          { id: "Garages", name: "Garages" },
          { id: "Technicians", name: "Technicians" },
        ]}
        selectedOption={selectedCategory}
        onChange={handleCategoryChange}
      />

      <div className="border-0 focus:outline-none focus:ring focus:ring-none active:none select-none hover:none mt-3 relative lg:h-10 lg:p-1.5">
        <Input
          style={{
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            WebkitBoxShadow: 'none',
            MozBoxShadow: 'none',
            appearance: 'none',
            background: 'transparent',
          }}
          disableUnderline={true}
          className="border-0 focus:outline-none   focus:ring focus:ring-none active:bg-white select-none hover:bg-white -top-4 lg:-top-5  w-[60%]"
          type="text"
          placeholder={`Search ${selectedCategory}`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleEnterPress();
            }
          }}
        />
        <button
          className="relative -top-2.5 lg:-top-2 -right-5 md:left-10 font-bold text-vivamagenta-accent"
          variant="contained"
          onClick={handleSearch}
        >
          <CgSearch className="text-3xl lg:text-3xl pr-1 pt-2  sm lg:pt-0 item-center" />
        </button>
      </div>
    </motion.div>
  );
};

export default SearchBar;
